
import Vue from "vue";
import API from "@/api/API";
import store from "@/store";
import DatePicker from "@/components/form-components/DatePicker.vue";
import rules from "@/services/helpers/validation-rules";
import BankAccount from "@/components/form-components/BankAccount.vue";
import ContactsPicker from "@/components/form-components/ContactsPicker.vue";
import { mask } from "vue-the-mask";
import CreateUser from "@/modules/users/views/create.vue";
import LiveSearch from "@/components/form-components/LiveSearch.vue";

export default Vue.extend({
  name: "Edit",

  components: {
    CreateUser,
    DatePicker,
    BankAccount,
    LiveSearch,
    ContactsPicker,
  },

  directives: { mask },

  props: {
    isDialog: {
      type: Boolean,
      default: false,
      required: false
    },
    serviceType: {
      required: false,
      type: Number
    }
  },

  data: () => ({
    rules,
    model: {
      banks: [] as Array<any>,
      type: "juridical",
      contacts: [] as Array<any>,
      user_id: null as any
    } as any,
    disableSave: true as boolean,
    fullLoaded: false as boolean,
    loading: false as boolean,
    errorMessages: {} as { [value: string]: Array<string> },
    showCreateUser: false as boolean,
    user: null as any,
    selects: {
      types: [] as Array<SelectComponentInterface>,
      banks: [] as Array<SelectComponentInterface>,
      users: [] as Array<SelectComponentInterface>,
      organizationForms: [] as Array<SelectComponentInterface>,
    },
  }),

  watch: {
    model: {
      deep: true,
      handler() {
        if (this.fullLoaded) {
          this.disableSave = false;
        }
      },
    },
  },

  computed: {
    maxDate() {
      return this.$moment().format("YYYY-MM-DD");
    },
    isJuridical() {
      return this.model.type === "juridical";
    },
  },

  async beforeRouteEnter(to, from, next) {
    try {
      const item = await API.clients().getForEdit(Number(to.params.id));
      const banks = await API.banks().getList();
      const types = await API.clients().getClientTypes();
      const organizationForms = await API.organizationForms().getList();

      next((vm) => {
        vm.model = item;
        vm.selects.banks = banks;
        vm.selects.types = types;
        vm.selects.organizationForms = organizationForms;

        if (item.user && item.user.length > 0) {
          vm.user = { value: item.user[0].value, text: item.user[0].text };
        }

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }

    try {
      const item = await API.banks().getList();

      next((vm) => {
        vm.selects.banks = item;

        vm.$nextTick(() => {
          vm.fullLoaded = true;
        });
      });
    } catch (e) {
      await store.dispatch("alert/showError", e.message);
    }
  },

  methods: {
    async submit(): Promise<void> {
      try {
        if ((this.$refs.form as any).validate()) {
          const model = { ...this.model };
          if (this.isJuridical) {
            delete model.identity_card;
            delete model.identity_card_issued;
            delete model.identity_card_date;
          } else {
            delete model.administrator_name;
            delete model.vat_code;
            delete model.certificate_date;
            delete model.certificate_number;
            delete model.organization_form_id;
          }

          model.user_id = model.user.value;
          await this.$API.clients().edit(Number(this.$route.params.id), model);
          await this.$router.push("/clients");
          await this.$store.dispatch(
            "alert/showSuccess",
            this.$t("global_alert.successful_edit")
          );
        }
      } catch (e) {
        if (e.hasOwnProperty("errors")) {
          this.errorMessages = e.errors;
          setTimeout(() => {
            this.errorMessages = {};
          }, 2000);
        }
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    toggleCreateUser(): void {
      this.showCreateUser = !this.showCreateUser;
    },
    async setUser(user: any): Promise<void> {
      try {
        this.model.user_id = user.value;
        this.user = user;
      } catch (e) {
        await this.$store.dispatch("alert/showError", e.message);
      }
    },
    async searchUser(searchValue: string): Promise<any> {
      try {
        return {
          items: await this.$API.users().liveSearchBeneficiary({ searchValue }),
        };
      } catch (e) {
        await this.$store.dispatch("alert/showError");
      }
    },
    async close() {
      if (this.isDialog) {
        this.$emit("close");
      } else {
        await this.$router.push("/clients");
      }
    },
  },
});
